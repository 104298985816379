import {WithCompanyId} from "./companyRequests";
import {PDFTemplateType} from "../interfaces";
import {FetchMethod, IndexedObject, sendFetch} from "@dvrd/dvr-controls";
import {Callback, WithData} from "./index";
import {URLS} from "../api";

interface WithMailSettingsID extends WithCompanyId {
    mail_settings_id: bigint;
}

interface CreateMailSettings extends WithCompanyId {
    entity_type: PDFTemplateType;
    mail_subject: string;
    html_template: string | null;
    html_config: IndexedObject<any> | null;
    legacy_template: string | null;
}

interface GetMailSettings extends WithCompanyId {
    entity_type: PDFTemplateType;
}

interface UpdateMailSettings extends WithMailSettingsID {
    mail_subject: string;
    html_template: string | null;
    html_config: IndexedObject<any> | null;
    legacy_template: string | null;
}

export function createMailSettings(params: WithData<CreateMailSettings> & Callback) {
    return sendFetch({
        url: URLS.POST.CREATE_MAIL_SETTINGS,
        method: FetchMethod.POST,
        ...params,
    });
}

export function getMailSettings(params: WithData<GetMailSettings> & Callback) {
    return sendFetch({
        url: URLS.POST.GET_MAIL_SETTINGS,
        method: FetchMethod.POST,
        ...params,
    });
}

export function updateMailSettings(params: WithData<UpdateMailSettings> & Callback) {
    return sendFetch({
        url: URLS.PUT.UPDATE_MAIL_SETTINGS,
        method: FetchMethod.PUT,
        ...params,
    });
}