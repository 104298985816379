import React from "react";

interface Props {
    width?: number | string;
    height?: number | string;
    className?: string;
}

export default function Mastercard(props: Props) {
    const width = props.width ?? '32', height = props.height ?? '32', { className } = props;
    return (
        <svg className={className} width={width} height={height} viewBox="-75 -150 750 750" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <desc>Created with Sketch.</desc>
                <defs>

                </defs>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="mastercard" fillRule="nonzero">
                        <rect id="Rectangle-1" fill="#000000" x="0" y="0" width="600" height="400" rx="40">

                        </rect>
                        <g id="Group" transform="translate(60, 40)">

                            <g>
                                <rect id="Rectangle-path" fill="#FF5F00" x="170.55" y="32.39" width="143.72" height="234.42">

                                </rect>
                                <path d="M185.05,149.6 C185.05997,103.912554 205.96046,60.7376085 241.79,32.39 C180.662018,-15.6713968 92.8620037,-8.68523415 40.103462,48.4380037 C-12.6550796,105.561241 -12.6550796,193.638759 40.103462,250.761996 C92.8620037,307.885234 180.662018,314.871397 241.79,266.81 C205.96046,238.462391 185.05997,195.287446 185.05,149.6 Z" id="Shape" fill="#EB001B">

                                </path>
                                <path d="M483.26,149.6 C483.30134,206.646679 450.756789,258.706022 399.455617,283.656273 C348.154445,308.606523 287.109181,302.064451 242.26,266.81 C278.098424,238.46936 299.001593,195.290092 299.001593,149.6 C299.001593,103.909908 278.098424,60.7306402 242.26,32.39 C287.109181,-2.86445052 348.154445,-9.40652324 399.455617,15.5437274 C450.756789,40.493978 483.30134,92.5533211 483.26,149.6 Z" id="Shape" fill="#F79E1B">

                                </path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
    )
}