import './style/adminUsers.scss';

import {
    AwesomeIcon,
    ChangeFunction,
    DvrdButton,
    DvrdInput,
    DvrdSelect,
    Loader,
    SelectItemShape
} from '@dvrd/dvr-controls';
import React, {KeyboardEventHandler, MouseEventHandler} from 'react';
import User from "../../../models/userModel";
import CommonPagination from "../../misc/commonPagination";
import Company from "../../../models/companyModel";

interface Props {
    onChangeSearch: ChangeFunction<string>;
    onEnterSearch: KeyboardEventHandler;
    onChangeLimit: ChangeFunction<number>;
    onChangePage: (value: number) => MouseEventHandler;
    onClickUser: (user: User) => MouseEventHandler;
    onClearSelectedUser: MouseEventHandler;
    onAdminLogin: MouseEventHandler;
    users: Array<User>;
    total: number;
    limit: number;
    page: number;
    search: string;
    loading: boolean;
    selectedUser: User | null;
}

const limitItems: Array<SelectItemShape> = [
    {value: 15, label: 15},
    {value: 25, label: 25},
    {value: 50, label: 50},
    {value: 100, label: 100},
]

export default function AdminUsers(props: Props) {
    const {
        loading, onChangeLimit, onChangePage, page, limit, onChangeSearch, search, onEnterSearch, users, total,
        onClickUser, selectedUser, onClearSelectedUser, onAdminLogin
    } = props;

    function renderSearch() {
        return (
            <div className='search-container'>
                <label className='page-title'>Gebruikers</label>
                <div className='actions'>
                    {!!selectedUser ? <DvrdButton onClick={onClearSelectedUser} label='Terug'/> : <div/>}
                    <DvrdInput value={search} onChange={onChangeSearch} onEnter={onEnterSearch} label='Gebruiker zoeken'
                               ornaments={{element: <AwesomeIcon name='search'/>}} className='search-field'/>
                </div>
            </div>
        );
    }

    function renderUsers() {
        return (
            <div className='users-container'>
                <Loader active={loading}/>
                <div className='user-row default-table-row head'>
                    <label>#</label>
                    <label>AANGEMAAKT</label>
                    <label>VOORNAAM</label>
                    <label>ACHTERNAAM</label>
                    <label>E-MAILADRES</label>
                    <label>ABONNEMENT</label>
                    <label>TYPE</label>
                    <label>LAATSTE LOGIN</label>
                </div>
                {users.map(renderUser)}
                {!users.length && <div className='default-table-row empty'>
                    <label className='empty-label'>- Geen gebruikers gevonden -</label>
                </div>}
            </div>
        )
    }

    function renderUser(user: User) {
        return (
            <div key={user.id} className='user-row default-table-row' onClick={onClickUser(user)}>
                <label>{user.id.toString()}</label>
                <label>{user.createdAtMoment.format('DD-MM-YYYY')}</label>
                <label>{user.firstname}</label>
                <label>{user.lastname}</label>
                <label>{user.email}</label>
                <label>{user.subscriptionStatus}</label>
                <label>{user.userType}</label>
                <label>{user.lastLoginMoment?.format('DD-MM-YYYY HH:mm') ?? '-'}</label>
            </div>
        )
    }

    function renderPagination() {
        return (
            <div className='pagination-container'>
                <CommonPagination onChangePage={onChangePage} page={page} total={total} limit={limit}/>
                <DvrdSelect onChange={onChangeLimit} value={limit} items={limitItems}/>
            </div>
        )
    }

    return (
        <div className='admin-users default-page'>
            {renderSearch()}
            {!!selectedUser ? <SelectedUser user={selectedUser} onAdminLogin={onAdminLogin}/> : <>
                {renderUsers()}
                {renderPagination()}
            </>}
        </div>
    )
}

interface SelectedProps {
    onAdminLogin: MouseEventHandler;
    user: User;
}

function SelectedUser(props: SelectedProps) {
    const {user, onAdminLogin} = props;

    function renderUserInfo() {
        return (
            <div className='user-info-container'>
                <div className='user-info'>
                    {renderUserField('#', user.id.toString())}
                    {renderUserField('Naam', user.fullname)}
                    {renderUserField('E-mailadres', user.email)}
                    {renderUserField('Type', user.userType)}
                    {renderUserField('Laatst ingelogd', user.lastLoginMoment?.format('DD-MM-YYYY') ?? '-')}
                    {renderUserField('Toestemming login', user.hasLoginConsent() ?
                        `Ja, tot ${user.adminConsentUntilMoment!.format('DD-MM-YYYY')}` : 'Nee')}
                </div>
                <div className='user-actions'>
                    <DvrdButton onClick={onAdminLogin} label='Inloggen' disabled={!user.hasLoginConsent()}/>
                </div>
            </div>
        )
    }

    function renderUserField(label: string, value: string | number) {
        return (
            <div className='user-field'>
                <label className='label'>{label}</label>
                <label>{value}</label>
            </div>
        )
    }

    function renderCompanies() {
        return (
            <div className='companies-container'>
                <label className='companies-title'>Bedrijven</label>
                <div className='company-row default-table-row head'>
                    <label>#</label>
                    <label>BEDRIJFSNAAM</label>
                    <label>E-MAILADRES</label>
                    <label>BTW NUMMER</label>
                    <label>KVK NUMMER</label>
                </div>
                {user.companies.map(renderCompany)}
                {!user.companies.length && <div className='default-table-row empty'>
                    <label className='empty-label'>- Deze gebruiker heeft nog geen bedrijf aangemaakt -</label>
                </div>}
            </div>
        )
    }

    function renderCompany(company: Company) {
        return (
            <div key={company.id.toString()} className='company-row default-table-row'>
                <label>{company.id.toString()}</label>
                <label>{company.companyName}</label>
                <label>{company.email}</label>
                <label>{company.vatNumber}</label>
                <label>{company.cocNumber}</label>
            </div>
        )
    }

    return (
        <div className='selected-user'>
            {renderUserInfo()}
            {renderCompanies()}
        </div>
    )
}