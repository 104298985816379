import {CookieAttributes} from "js-cookie";
import {getCookie, getJwt, getMode, navigate, removeCookie, ResponseData, setCookie, setJwt} from "@dvrd/dvr-controls";
import User from "../models/userModel";
import {ContextShape} from "./interfaces";

let adminJwt: string | null = null;
const cookieName = 'dvrd_facturatie_admin_jwt';

export function setAdminJwt(jwt: string | null) {
    adminJwt = jwt;
    const args: CookieAttributes = getCookieArgs();
    if (!jwt) removeCookie(cookieName, args);
    else setCookie(cookieName, jwt, args);
}

export function getAdminJwt(): string | null {
    if (!adminJwt) adminJwt = getCookie(cookieName) || null;
    return adminJwt;
}

export function hasAdminLogin(): boolean {
    return adminJwt !== null;
}

export function successfulAdminLogin(user: User, data: ResponseData, context: ContextShape) {
    const adminJwt = getJwt();
    if (adminJwt) {
        setAdminJwt(adminJwt);
        setJwt(data.token);
        context.onChangeContext({
            user,
            company: user.companies.length ? user.companies[0] : null
        }, () => {
            navigate('/facturen');
        });
    }
};

export const onAdminLogout = (context: ContextShape): boolean => {
    const adminJwt = getAdminJwt();
    if (!adminJwt) return false;
    setJwt(adminJwt);
    User.get((user: User | null) => {
        if (user) {
            context.onChangeContext({
                user,
                company: user.companies.length ? user.companies[0] : null
            }, () => {
                navigate('/admin/gebruikers');
            });
        } else navigate('/inloggen');
    });
    setAdminJwt(null);
    return true;
}

function getCookieArgs(): CookieAttributes {
    let args: CookieAttributes = {expires: 1, sameSite: 'strict'};
    if (getMode() !== 'dev') {
        args.secure = true;
        args.sameSite = 'strict';
    }
    return args;
}