import {Callback, Paginated, WithData} from "./index";
import {FetchMethod, sendFetch} from "@dvrd/dvr-controls";
import {createParamUrl, URLS} from "../api";

interface GetUsers extends Paginated {
    search: string | null;
}

interface WithUserID {
    user_id: string;
}

export function getUsers(params: WithData<GetUsers> & Callback) {
    return sendFetch({
        url: URLS.POST.GET_USERS,
        method: FetchMethod.POST,
        ...params,
    });
}

export function adminGetUser(params: WithUserID & Callback) {
    return sendFetch({
        url: createParamUrl(URLS.GET.GET_ADMIN_USER, {user_id: params.user_id}),
        callback: params.callback,
    });
}

export function adminLogin(params: WithData<WithUserID> & Callback) {
    return sendFetch({
        url: URLS.POST.ADMIN_LOGIN,
        method: FetchMethod.POST,
        ...params,
    });
}