import {FetchMethod, sendFetch} from "@dvrd/dvr-controls";
import {WithCompanyId} from "./companyRequests";
import {Callback, WithData} from "./index";
import {createParamUrl, URLS} from "../api";
import {PDFTemplateType} from "../interfaces";

interface GetPDFTemplate extends WithCompanyId {
    template_type: PDFTemplateType;
}

export function getSelectablePDFTemplates(params: WithCompanyId & Callback) {
    return sendFetch({
        url: createParamUrl(URLS.GET.SELECTABLE_PDF_TEMPLATES, {company_id: params.company_id.toString()}),
        callback: params.callback
    });
}

export function getPDFTemplate(params: WithData<GetPDFTemplate> & Callback) {
    return sendFetch({
        url: URLS.POST.GET_PDF_TEMPLATE,
        method: FetchMethod.POST,
        ...params,
    })
}
