import {BaseFields, BaseModel, BaseProps, createFieldDef, FieldDefinition, ModelFieldType} from './baseModel';
import {ModelResponse, ResponseData} from "@dvrd/dvr-controls";
import Company from './companyModel';
import {setRexelConfig} from "../util/requests/rexelRequests";

interface Props extends BaseProps {
    username?: string;
    password?: string;
    company_id?: bigint;
    company?: Company | null | any;
}

interface Fields extends BaseFields {
    username: string;
    password: string;
    company_id: bigint;
    company: Company | null;
}

const fields: Fields = {
    id: -1n,
    created_at: '',
    updated_at: '',
    username: '',
    password: '',
    company_id: -1n,
    company: null,
};

const fieldsDefinition: { [key in keyof Omit<Fields, 'company'>]: FieldDefinition } = {
    id: createFieldDef(ModelFieldType.BIGINT),
    created_at: createFieldDef(ModelFieldType.STRING),
    updated_at: createFieldDef(ModelFieldType.STRING, undefined, true),
    username: createFieldDef(ModelFieldType.STRING),
    password: createFieldDef(ModelFieldType.STRING),
    company_id: createFieldDef(ModelFieldType.BIGINT),
};

const ignoreProps: (keyof Fields)[] = ['company'];

export default class RexelConfig extends BaseModel<Props, Fields> {
    constructor(props?: Props) {
        super(ignoreProps, fieldsDefinition, fields, props);
        this.build(props);
    }

    get username(): string {
        return this.fields.username;
    }

    set username(username: string) {
        this.fields.username = username;
    }

    get password(): string {
        return this.fields.password;
    }

    set password(password: string) {
        this.fields.password = password;
    }

    get companyId(): bigint {
        return this.fields.company_id;
    }

    set companyId(company_id: bigint) {
        this.fields.company_id = company_id;
    }

    get company(): Company | null {
        return this.fields.company;
    }

    set company(company: Company | null) {
        this.fields.company = company;
        if (company) this.fields.company_id = company.id;
    }

    clone = (): RexelConfig => {
        const fields: Fields = {
            id: this.id, company: this.company, company_id: this.companyId, created_at: this.createdAt,
            password: this.password, updated_at: this.updatedAt, username: this.username
        }
        return new RexelConfig(fields);
    }

    save = (callback: ModelResponse<RexelConfig>) => {
        setRexelConfig({
            data: {
                company_id: this.companyId, password: this.password, username: this.username
            }, callback: (data: ResponseData) => {
                if (data.success) this.build(data.config);
                callback(this, data.success, data);
            }
        })

    };

    protected build = (props?: Props) => {
        this.constructFields(props);
        if (props) {
            if (props.company)
                this.fields.company = props.company instanceof Company ? props.company : new Company(props.company);
        }
    };
}