import {WithCompanyId} from "./companyRequests";
import {Callback, WithData} from "./index";
import {FetchMethod, sendFetch} from "@dvrd/dvr-controls";
import {URLS} from "../api";

interface GetDashboardStats extends WithCompanyId {
    start: string;
    end: string;
}

export function getSimpleDashboardStats(params: WithData<GetDashboardStats> & Callback) {
    return sendFetch({
        url: URLS.POST.SIMPLE_DASHBOARD_STATS,
        method: FetchMethod.POST,
        ...params,
    });
}