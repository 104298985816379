import React, {useContext, useEffect, useState} from 'react';
import User from "../../../models/userModel";
import {nullify, ResponseData, showDialog} from '@dvrd/dvr-controls';
import AdminUsers from "./adminUsers";
import {successfulAdminLogin} from "../../../util/adminUtil";
import {AppContext} from "../../context/appContext";

type LoadOptions = { page?: number; limit?: number; search?: string };

export default function AdminUsersController() {
    const context = useContext(AppContext);
    const [users, setUsers] = useState<Array<User>>([]);
    const [loading, setLoading] = useState(true);
    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [total, setTotal] = useState(0);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);

    function onChangeSearch(value: string) {
        setSearch(value);
    }

    function onEnterSearch() {
        loadUsers({page: 0});
    }

    function onChangeLimit(value: number) {
        setLimit(limit);
        loadUsers({limit: value, page: 0});
    }

    function onChangePage(value: number) {
        return function () {
            setPage(value);
            loadUsers({page: value});
        }
    }

    function onClickUser(user: User) {
        return function () {
            setLoading(true);
            User.adminGet(user.id, (user: User | null, success: boolean, data: ResponseData) => {
                setLoading(false);
                if (success) setSelectedUser(user);
                else showDialog(data.message ?? 'Het laden van de gebruiker is niet gelukt.', 'Laden mislukt');
            })
        }
    }

    function onClearSelectedUser() {
        setSelectedUser(null);
    }

    function onAdminLogin() {
        if(!selectedUser) return;
        setLoading(true);
        User.adminLogin(selectedUser.id, (user: User | null, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) successfulAdminLogin(user!, data, context);
            else showDialog(data.message ?? 'Het inloggen is niet gelukt.', 'Inloggen mislukt');
        });
    }

    function loadUsers(options?: LoadOptions) {
        setLoading(true);
        const _page = options?.page ?? page;
        const _limit = options?.limit ?? limit;
        const _search = nullify(options?.search ?? search);
        User.getAll(_search, _limit, _page, (users: Array<User>, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                setSelectedUser(null);
                setUsers(users);
                setTotal(data.total);
            } else showDialog(data.message ?? 'Het laden van de gebruikers is niet gelukt.', 'Laden mislukt');
        })
    }

    useEffect(() => {
        loadUsers();
    }, []);

    return (
        <AdminUsers onChangeSearch={onChangeSearch} onEnterSearch={onEnterSearch} onChangeLimit={onChangeLimit}
                    onChangePage={onChangePage} onClickUser={onClickUser} onClearSelectedUser={onClearSelectedUser}
                    onAdminLogin={onAdminLogin} users={users} total={total} limit={limit} page={page} search={search}
                    loading={loading} selectedUser={selectedUser}/>
    );
}