import {FetchMethod, sendFetch} from "@dvrd/dvr-controls";
import {WithCompanyId} from "./companyRequests";
import {Callback, WithData} from "./index";
import {URLS} from "../api";

interface ReportIssue extends WithCompanyId {
    issue: string;
}

export function reportIssue(params: WithData<ReportIssue> & Callback) {
    return sendFetch({
        url: URLS.POST.REPORT_ISSUE,
        method: FetchMethod.POST,
        ...params,
    });
}