import React, {
    ForwardedRef,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import {ActivateHandle, EntityType, FinDocumentItem} from "../../../../util/interfaces";
import {ErrorType, ResponseData, showDialog} from '@dvrd/dvr-controls';
import InvoiceItem from "../../../../models/invoiceItemModel";
import OfferItem from "../../../../models/offerItemModel";
import Product from "../../../../models/productModel";
import CatalogProductSelectionController
    from "../../../catalog/catalogProductSelection/catalogProductSelectionController";
import MobileAddItem from "./mobileAddItem";
import {createErrorMessage} from "../../../../util/utils";
import {AppContext} from "../../../context/appContext";

interface Props {
    onAdd: (item: FinDocumentItem, reverseCharged: boolean) => void;
    reverseCharged: boolean;
}

function MobileAddItemController(props: Props, ref: ForwardedRef<ActivateHandle>) {
    const context = useContext(AppContext);
    const {onAdd, reverseCharged} = props;
    const [active, setActive] = useState(false);
    const [item, setItem] = useState<FinDocumentItem | null>(null);
    const [error, setError] = useState<ErrorType>(null);
    const [products, setProducts] = useState<Array<Product>>([]);
    const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
    const catalogRef = useRef<ActivateHandle>(null);
    const catalogItemType = useMemo(() => {
        if (item instanceof OfferItem) return EntityType.OFFER;
        return EntityType.INVOICE;
    }, [item]);

    function onActivate(itemType: EntityType) {
        const id = BigInt(Math.round(Math.random() * 1000000));
        if (itemType === EntityType.INVOICE)
            setItem(new InvoiceItem({id}));
        else setItem(new OfferItem({id}));
        setActive(true);
    }

    function onClose() {
        setActive(false);
        setItem(null);
        setError(null);
        setSelectedProduct(null);
    }

    function onSelectProduct(productId: string) {
        setSelectedProduct(productId);
        const product = getProduct(productId);
        if (!product || !item) return;
        item.title = product.title;
        item.price = product.price;
        item.vatPercentage = product.vatPercentage;
        item.inclVat = product.inclVat;
        item.amount = 1;
        setItem(item.copy);
    }

    function onChange(key: string) {
        return function (value: any) {
            if (!item) return;
            setItem(item.copy.setField(key, value));
        }
    }

    function onSubmit() {
        if (!item || !validateValues()) return;
        console.debug(item);
        onAdd(item, reverseCharged);
        onClose();
    }

    function onClickCatalogProducts() {
        catalogRef.current?.activate();
    }

    function onSubmitCatalog(items: Array<FinDocumentItem>) {
        for (const item of items)
            onAdd(item, reverseCharged);
        onClose();
    }

    function getProduct(productId: string): Product | null {
        for (const product of products)
            if (product.id.toString() === productId) return product.copy;
        return null;
    }

    function validateValues(): boolean {
        if (!item?.title.length) {
            setError('De omschrijving kan niet leeg zijn');
            return false;
        } else if (!item.amount) {
            setError('Het aantal kan niet 0 zijn');
            return false;
        } else if (!item.price) {
            setError('De prijs kan niet 0 zijn');
            return false;
        }
        return true;
    }

    function loadProducts() {
        const {company} = context.companyContext;
        if (!company) return;
        Product.getAll(company.id, -1, -1, '',
            (products: Product[], success: boolean, data: ResponseData) => {
                if (success) setProducts(products);
                else showDialog(createErrorMessage(data.message ?? 'Het laden van de producten is niet gelukt.'),
                    'Producten laden mislukt');
            })
    }

    useImperativeHandle(ref, () => ({activate: onActivate}));
    useEffect(() => {
        loadProducts();
    }, []);

    return (
        <>
            <MobileAddItem onClose={onClose} onChange={onChange} onSubmit={onSubmit}
                           onClickCatalogProducts={onClickCatalogProducts} onSelectProduct={onSelectProduct}
                           active={active} item={item} error={error} products={products}
                           selectedProduct={selectedProduct} reverseCharged={reverseCharged}/>
            <CatalogProductSelectionController ref={catalogRef} onSubmit={onSubmitCatalog} itemType={catalogItemType}/>
        </>
    )
}

export default forwardRef<ActivateHandle, Props>(MobileAddItemController);