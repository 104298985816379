import './style/appError.scss';

import React, {useContext, useEffect, useRef, useState} from 'react';
import Image from "../controls/image";
import {captureException, captureUserFeedback} from '@sentry/react';
import {DvrdButton, DvrdInput} from '@dvrd/dvr-controls';
import {AppContext} from "../context/appContext";
import {useRouteError} from 'react-router';

export default function ErrorPage() {
    const context = useContext(AppContext);
    const sentryEventID = useRef<string | null>(null);
    const error = useRouteError();
    const [feedback, setFeedback] = useState('');
    const [feedbackDisabled, setFeedbackDisabled] = useState(false);

    function onChangeFeedback(value: string) {
        setFeedback(value);
    }

    function onSubmitFeedback() {
        if (!sentryEventID.current || !feedback.length) return;
        const {user} = context.userContext;
        const name = user?.fullname ?? '';
        const email = user?.email;
        captureUserFeedback({comments: feedback, event_id: sentryEventID.current, name, email});
        setFeedbackDisabled(true);
        alert('Bedankt voor je feedback!');
    }

    useEffect(() => {
        if (error instanceof Error)
            sentryEventID.current = captureException(error);
    }, []);

    return (
        <div className='error-page'>
            <div className='inner-container'>
                <Image name='system-error' className='system-error-img'/>
                <h2>Er is iets misgegaan!</h2>
                <p className='message'>Het probleem is gemeld en zal zo snel mogelijk onderzocht worden.
                    <br/>Je kunt eventueel nog informatie toevoegen aan de melding via onderstaand tekstveld.</p>
                <DvrdInput value={feedback} onChange={onChangeFeedback} inputProps={{rows: 10}}
                           placeholder='Extra informatie (optioneel)' className='feedback-field'
                           disabled={feedbackDisabled} area/>
                <DvrdButton onClick={onSubmitFeedback} label='Extra informatie toevoegen' disabled={feedbackDisabled}/>
            </div>
        </div>
    );
}