import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import PDFTemplate from "../../models/pdfTemplateModel";
import {PDFTemplateType} from "../../util/interfaces";
import {ResponseData, showDialog} from '@dvrd/dvr-controls';
import {createErrorMessage} from "../../util/utils";
import {onSetCrumbs} from "../../util/events";
import PDFEditorController from "./editor/PDFEditorController";

interface Props {
    templateType: PDFTemplateType;
}

export default function PDFSelectController(props: Props) {
    const context = useContext(AppContext);
    const {company} = context.companyContext;
    const {templateType} = props;
    const [loading, setLoading] = useState(true);
    const [template, setTemplate] = useState<PDFTemplate | null>(null);

    function loadTemplate() {
        if (!company) return;
        setLoading(true);
        PDFTemplate.get(templateType, company.id,
            (pdfTemplate: PDFTemplate, success: boolean, data: ResponseData) => {
                setLoading(false);
                if (success) setTemplate(pdfTemplate);
                else showDialog(createErrorMessage(data.message ??
                    'Het laden van de layout is niet gelukt.'), 'Laden mislukt');
            })
    }

    useEffect(() => {
        let label: string;
        let route: string;
        if (templateType === PDFTemplateType.INVOICE) {
            label = 'Factuur Layout';
            route = '/factuur-layout';
        } else {
            label = 'Offerte Layout';
            route = '/offerte-layout';
        }
        onSetCrumbs([{label, route}]);
        loadTemplate();
    }, [templateType]);

    return (
        <PDFEditorController template={template} templateType={templateType} loading={loading}/>
    )
}