import './style/mobileAddItem.scss';

import {
    ChangeFunction,
    ChangeKeyFunction,
    Checkbox,
    CloseButton,
    DvrdButton,
    DvrdInput,
    DvrdNumberInput,
    DvrdSelect,
    ErrorType,
    preventDefault,
    SelectItemShape,
    WithBackground
} from '@dvrd/dvr-controls';
import React, {MouseEventHandler, useContext, useMemo} from 'react';
import {FinDocumentItem, ModuleName} from "../../../../util/interfaces";
import InvoiceItem from "../../../../models/invoiceItemModel";
import Product from "../../../../models/productModel";
import {vatItems} from "../../../../util/helper";
import {hasModule} from "../../../../util/moduleUtil";
import {AppContext} from "../../../context/appContext";

interface Props {
    onClose: MouseEventHandler;
    onChange: ChangeKeyFunction;
    onSubmit: MouseEventHandler;
    onClickCatalogProducts: MouseEventHandler;
    onSelectProduct: ChangeFunction;
    active: boolean;
    item: FinDocumentItem | null;
    error: ErrorType;
    products: Array<Product>;
    selectedProduct: string | null;
    reverseCharged: boolean;
}

function getProductItemLabel(product: Product) {
    if (product.code) return `${product.code} - ${product.title}`;
    return product.title;
}

export default function MobileAddItem(props: Props) {
    const context = useContext(AppContext);
    const {
        item, active, error, onSubmit, onClose, onChange, onClickCatalogProducts, products, onSelectProduct,
        selectedProduct, reverseCharged
    } = props;
    const title = useMemo(() => {
        if (item instanceof InvoiceItem) return 'Factuurregel toevoegen';
        else return 'Offerteregel toevoegen';
    }, [item]);
    const productItems: Array<SelectItemShape> = useMemo(() => [{
        value: '-1',
        label: ''
    }].concat(products.map((product: Product) => ({
        value: product.id.toString(),
        label: getProductItemLabel(product)
    }))), [products]);
    const _vatItems: Array<SelectItemShape> = useMemo(() => {
        if (reverseCharged) return [{value: -1, label: 'Verlegd'}];
        return vatItems.slice(1);
    }, [reverseCharged]);

    function renderForm() {
        if(!item) return null;
        return (
            <form className='body-container' onSubmit={preventDefault}>
                {hasModule(context.companyContext.company, ModuleName.CATALOG) &&
                    <DvrdButton onClick={onClickCatalogProducts} label='+ Toevoegen uit catalogus'
                                className='catalog-button'/>}
                <DvrdSelect onChange={onSelectProduct} value={selectedProduct ?? ''}
                            items={productItems} label='Product' selectOnly={false} className='product-select'
                            itemContainerClassName='crm-select' itemsPosition='top'/>
                <DvrdInput onChange={onChange('title')} value={item.title} label='Productnaam'
                           placeholder='Productnaam'/>
                <div className='row'>
                    <DvrdNumberInput onChange={onChange('amount_string')} value={item.amount} label='Aantal'/>
                    <DvrdNumberInput onChange={onChange('price_string')} value={item.price} label='Prijs' asCurrency/>
                </div>
                <div className='row'>
                    <DvrdSelect onChange={onChange('vat_percentage')} value={reverseCharged ? -1 : item.vatPercentage}
                                items={_vatItems} label='BTW' itemContainerClassName='crm-select'
                                disabled={reverseCharged} itemsPosition='top'/>
                    {reverseCharged ? <div/> :
                        <Checkbox checked={item.inclVat} onCheck={onChange('incl_vat')} label='Prijs incl. BTW'/>}
                </div>
                {error && <label className='error-label'>{error}</label>}
            </form>
        )
    }

    return (
        <WithBackground active={active} onClose={onClose}>
            <div className='mobile-add-fin-item default-popup'>
                <div className='header-container'>
                    <label className='header-label'>{title}</label>
                    <CloseButton onClick={onClose}/>
                </div>
                {renderForm()}
                <div className='footer-container btn-2'>
                    <DvrdButton onClick={onClose} label='Annuleren' secondary/>
                    <DvrdButton onClick={onSubmit} label='Toevoegen'/>
                </div>
            </div>
        </WithBackground>
    )
}