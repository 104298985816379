import './style/offersPage.scss';

import {
    AwesomeIcon,
    ChangeFunction,
    deviceIsMobile,
    DvrdInput,
    DvrdSelect,
    Loader,
    MenuPlacement,
    OptionsMenu,
    OptionsMenuItem,
    SelectItemShape,
    stopPropagation,
    voidFunction
} from '@dvrd/dvr-controls';
import React, {KeyboardEventHandler, MouseEventHandler} from 'react';
import Offer from "../../models/offerModel";
import AddButton from "../controls/button/addButton";
import {parseMoney} from "../../util/utils";
import classNames from 'classnames';
import {IconName} from '@fortawesome/fontawesome-svg-core';

const limitItems: Array<SelectItemShape> = [
    {label: 10, value: 10},
    {label: 20, value: 20},
    {label: 50, value: 50},
    {label: 100, value: 100},
]

type OfferClickFunction = (offer: Offer) => MouseEventHandler;

interface Props {
    onReset: MouseEventHandler;
    onChangeSearch: ChangeFunction<string>;
    onChangePage: (page: number) => MouseEventHandler;
    onChangeLimit: ChangeFunction<number>;
    onEnterSearch: KeyboardEventHandler;
    onClickOffer: OfferClickFunction;
    onEditOffer: OfferClickFunction;
    onSendOffer: OfferClickFunction;
    onDownloadOffer: OfferClickFunction;
    onPreviewOffer: OfferClickFunction;
    onDeleteOffer: OfferClickFunction;
    onClickAddOffer: MouseEventHandler;
    onCreateInvoice: OfferClickFunction;
    onClickInvoice: OfferClickFunction;
    offers: Array<Offer>;
    total: number;
    search: string;
    page: number;
    limit: number;
    loading: boolean;
}

export default function OffersPage(props: Props) {
    const {
        offers, onDownloadOffer, onPreviewOffer, onDeleteOffer, onSendOffer, onEditOffer, onClickOffer,
        onClickAddOffer, page, onChangePage, onChangeLimit, limit, onChangeSearch, onEnterSearch, search, total,
        onReset, loading, onCreateInvoice, onClickInvoice
    } = props;

    function getOptions(offer: Offer): Array<OptionsMenuItem> {
        const options: Array<OptionsMenuItem> = [
            {icon: <AwesomeIcon name='eye' className='icon'/>, label: 'Details', onClick: onClickOffer(offer)},
            {
                icon: <AwesomeIcon name='file-pdf' className='icon'/>,
                label: 'Voorbeeld',
                onClick: onPreviewOffer(offer)
            },
            {
                icon: <AwesomeIcon name='download' className='icon'/>,
                label: 'Downloaden',
                onClick: onDownloadOffer(offer)
            },
            {
                icon: <AwesomeIcon name='paper-plane' className='icon'/>,
                label: 'Verzenden',
                onClick: onSendOffer(offer)
            },
            {icon: <AwesomeIcon name='pen' className='icon'/>, label: 'Wijzigen', onClick: onEditOffer(offer)},
        ];
        if (offer.invoiceId) options.push({
            icon: <AwesomeIcon name='file-pdf' className='icon'/>,
            label: 'Naar factuur',
            onClick: onClickInvoice(offer),
        });
        else options.push({
            icon: <AwesomeIcon name='file-pdf' className='icon'/>,
            label: 'Factuur aanmaken',
            onClick: onCreateInvoice(offer),
        });
        if (!offer.isSent)
            options.push({
                icon: <AwesomeIcon name='trash-alt' className='icon red'/>,
                label: 'Verwijderen',
                onClick: onDeleteOffer(offer)
            });
        return options;
    }

    function renderSearchContainer() {
        return (
            <div className='search-container'>
                <DvrdInput onChange={onChangeSearch} value={search} label='Offerte zoeken'
                           placeholder='Zoeken...' onEnter={onEnterSearch} className='search-field'
                           onClearInput={onReset} autoFocus={!deviceIsMobile()}/>
                <AddButton onClick={onClickAddOffer}/>
            </div>
        )
    }

    function renderOffers() {
        return (
            <div className='offers-container'>
                <Loader active={loading}/>
                <div className='row head'>
                    <label className='no-mob'>NUMMER</label>
                    <label>DATUM</label>
                    <label>RELATIE</label>
                    <label>BEDRAG</label>
                    <label className='no-mob'>STATUS</label>
                    <label/>
                </div>
                {offers.map(renderOffer)}
                {offers.length === 0 &&
                    <label className='empty-label'>Je hebt nog geen offertes aangemaakt</label>}
            </div>
        )
    }

    function renderOffer(offer: Offer, index: number) {
        const {offerNumber, offerDateMoment} = offer;
        let optionsRef = React.createRef<OptionsMenu>();
        const onClick = () => {
            optionsRef.current?.onOpenExternal();
        }
        return (
            <div key={index} className='row' onClick={onClick} onDoubleClick={onClickOffer(offer)}>
                <label className='no-mob'>{offerNumber}</label>
                <label>{offerDateMoment.format('DD-MM-YYYY')}</label>
                <label>{offer.relation?.displayName ?? '-'}</label>
                <label>&euro; {parseMoney(offer.totalIncl)}</label>
                {renderOfferStatus(offer)}
                <OptionsMenu options={getOptions(offer)} optionsComponent={<AwesomeIcon name='ellipsis-h'/>}
                             onClickOptionIcon={stopPropagation} placement={MenuPlacement.BOTTOM_LEFT}
                             containerClass='offer-options' ref={optionsRef}/>
            </div>
        )
    }

    function renderOfferStatus(offer: Offer) {
        const isSent = !!offer.sentAt;
        const isDownloaded = !!offer.downloadedAt;
        let statuses = 0;
        if (isSent) statuses++;
        if (isDownloaded) statuses++;
        if (!!offer.invoiceId) statuses++;
        return (
            <div className='status-container no-mob'
                 style={{gridTemplateColumns: `repeat(${statuses}, fit-content(100%))`}}>
                {isSent && renderStatusBlock('paper-plane', 'blue', 'Verzonden')}
                {isDownloaded && renderStatusBlock('download', 'yellow', 'Opgeslagen')}
                {!!offer.invoiceId && renderStatusBlock('file-pdf', 'green', 'Factuur aangemaakt')}
            </div>
        )
    }

    function renderStatusBlock(icon: IconName, color: string, title: string) {
        return (
            <div className={classNames('status-block', color)} title={title}>
                <AwesomeIcon name={icon} className='icon'/>
            </div>
        )
    }

    function renderFooter() {
        const countLabel = total === 1 ? 'offerte' : 'offertes';
        const from = Math.min(total, page * limit + 1);
        const to = Math.min(total, (page + 1) * limit);
        const prevDisabled = page === 0;
        const nextDisabled = page === Math.ceil(total / limit) - 1;
        return (
            <div className='footer-container'>
                <label>{total} {countLabel}</label>
                <div className='pagination'>
                    <DvrdSelect items={limitItems} onChange={onChangeLimit} value={limit} itemsPosition='top'/>
                    <label>{from} - {to}</label>
                    <AwesomeIcon name='chevron-left' className={classNames('pager', prevDisabled && 'disabled')}
                                 onClick={prevDisabled ? voidFunction : onChangePage(page - 1)}/>
                    <AwesomeIcon name='chevron-right' className={classNames('pager', nextDisabled && 'disabled')}
                                 onClick={nextDisabled ? voidFunction : onChangePage(page + 1)}/>
                </div>
            </div>
        );
    }

    return (
        <div className='offers-page'>
            {renderSearchContainer()}
            {renderOffers()}
            {renderFooter()}
        </div>
    )
}