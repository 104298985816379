import React, {useContext, useEffect, useRef, useState} from 'react';
import {AppContext} from "../context/appContext";
import Offer from "../../models/offerModel";
import {ResponseData, showDialog, showSnackbar, toMoment, useEvent} from '@dvrd/dvr-controls';
import {createErrorMessage} from "../../util/utils";
import {onSetCrumbs} from "../../util/events";
import {useNavigate} from 'react-router';
import {ActivateHandle} from '../../util/interfaces';
import RecipientSelectController from "../recipientSelect/recipientSelectController";
import OffersPage from "./offersPage";
import Invoice from "../../models/invoiceModel";
import {subscriptionRequiredAction} from "../../util/subscriptionUtil";

type LoadConfig = { search?: string; page?: number; limit?: number; }

export default function OffersPageController() {
    const context = useContext(AppContext);
    const {company} = context.companyContext;
    const {subscription} = context.subscriptionContext;
    const [offers, setOffers] = useState<Array<Offer>>([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(true);
    const sendingOffer = useRef<Offer | null>(null);
    const recipientRef = useRef<ActivateHandle>(null);
    const navigate = useNavigate();
    useEvent('onResetInvoices', onReset);

    function onReset() {
        setSearch('');
        setPage(0);
        loadOffers({search: '', page: 0});
    }

    function onChangeSearch(value: string) {
        setSearch(value);
    }

    function onChangePage(page: number) {
        return function () {
            setPage(page);
            loadOffers({page});
        }
    }

    function onChangeLimit(limit: number) {
        setLimit(limit);
        loadOffers({limit});
    }

    function onEnterSearch() {
        setPage(0);
        loadOffers({page: 0});
    }

    function onClickOffer(offer: Offer) {
        return function () {
            navigate(`/offertes/${offer.id}`);
        }
    }

    function onEditOffer(offer: Offer) {
        return function (evt: React.MouseEvent) {
            evt.stopPropagation();
            subscriptionRequiredAction(subscription, navigate, `/offertes/wijzigen/${offer.id}`);
        }
    }

    function onSendOffer(offer: Offer) {
        return function (evt: React.MouseEvent) {
            evt.stopPropagation();
            subscriptionRequiredAction(subscription, function () {
                if (offer.getAllEmails().length > 1) {
                    sendingOffer.current = offer;
                    return recipientRef.current?.activate(offer.getAllEmails());
                }
                setLoading(true);
                offer.send([], (offer: Offer, success: boolean, data: ResponseData) => {
                    setLoading(false);
                    if (success) {
                        showSnackbar('De offerte is verzonden');
                        replaceOffer(offer);
                    } else
                        showDialog(createErrorMessage(data.message ?? 'Het verzenden van de offerte is niet gelukt.'),
                            'Verzenden mislukt');
                });
            });
        }
    }

    function onRecipientSelect(recipients: string[]) {
        const offer = sendingOffer.current;
        if (!offer) return;
        setLoading(true);
        offer.send(recipients, (offer: Offer, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                showSnackbar('De offerte is verzonden');
                replaceOffer(offer);
            } else
                showDialog(createErrorMessage(data.message ?? 'Het verzenden van de offerte is niet gelukt.'),
                    'Verzenden mislukt');
        });
    }

    function onDownloadOffer(offer: Offer) {
        return function (evt: React.MouseEvent) {
            evt.stopPropagation();
            subscriptionRequiredAction(subscription, function () {
                setLoading(true);
                offer.download(() => {
                    setLoading(false);
                    offer = offer.copy;
                    offer.downloadedAtMoment = toMoment();
                    replaceOffer(offer);
                });
            });
        }
    }

    function onPreviewOffer(offer: Offer) {
        return function (evt: React.MouseEvent) {
            evt.stopPropagation();
            subscriptionRequiredAction(subscription, function () {
                setLoading(true);
                offer.preview(() => {
                    setLoading(false);
                });
            });
        }
    }

    function onDeleteOffer(offer: Offer) {
        return function (evt: React.MouseEvent) {
            evt.stopPropagation();
            subscriptionRequiredAction(subscription, function () {
                showDialog('Weet je zeker dat je deze offerte wilt verwijderen?', 'Bevestig',
                    ['Nee', {label: 'Ja', onClick: onConfirmDeleteOffer(offer), primary: false}]);
            });
        }
    }

    function onConfirmDeleteOffer(offer: Offer) {
        return function () {
            setLoading(true);
            offer.delete((offer: Offer, success: boolean, data: ResponseData) => {
                if (success) {
                    showSnackbar('De offerte is verwijderd');
                    loadOffers();
                } else {
                    setLoading(false);
                    showDialog(createErrorMessage(data.message ?? 'Het verwijderen van de offerte is niet gelukt.'),
                        'Verwijderen mislukt');
                }
            });
        }
    }

    function onClickAddOffer() {
        subscriptionRequiredAction(subscription, navigate, '/offertes/toevoegen');
    }

    function onCreateInvoice(offer: Offer) {
        return function () {
            subscriptionRequiredAction(subscription, function () {
                setLoading(true);
                offer.createInvoice((invoice: Invoice, success: boolean, data: ResponseData) => {
                    setLoading(false);
                    if (success) {
                        showSnackbar('De factuur is aangemaakt');
                        navigate(`/facturen/${invoice.id}`);
                    } else showDialog(createErrorMessage(data.message ?? 'Het aanmaken van de factuur is niet gelukt.'),
                        'Factuur aanmaken mislukt');
                });
            });
        }
    }

    function onClickInvoice(offer: Offer) {
        return function () {
            if (!offer.invoiceId) return;
            navigate(`/facturen/${offer.invoiceId}`);
        }
    }

    function replaceOffer(offer: Offer) {
        const _offers = offers.slice();
        for (let i = 0; i < _offers.length; i++) {
            if (_offers[i].id === offer.id) {
                _offers.splice(i, 1, offer);
                return setOffers(_offers);
            }
        }
    }

    function loadOffers(config?: LoadConfig) {
        if (!company) return;
        setLoading(true);
        const _search = config?.search ?? search;
        const _page = config?.page ?? page;
        const _limit = config?.limit ?? limit;
        Offer.getAll(company.id, _search, _page, _limit, null,
            (offers: Array<Offer>, success: boolean, data: ResponseData) => {
                setLoading(false);
                if (success) {
                    setOffers(offers);
                    setTotal(data.total);
                } else
                    showDialog(createErrorMessage(data.message ?? 'Het laden van de offertes is niet gelukt.'),
                        'Laden mislukt');
            })
    }

    useEffect(() => {
        onSetCrumbs([{label: 'Offertes', route: '/offertes'}]);
    }, []);

    useEffect(() => {
        loadOffers();
    }, [company]);

    return (
        <>
            <OffersPage onReset={onReset} onChangeSearch={onChangeSearch} onChangePage={onChangePage}
                        onChangeLimit={onChangeLimit} onEnterSearch={onEnterSearch} onClickOffer={onClickOffer}
                        onEditOffer={onEditOffer} onSendOffer={onSendOffer} onDownloadOffer={onDownloadOffer}
                        onPreviewOffer={onPreviewOffer} onDeleteOffer={onDeleteOffer} onClickAddOffer={onClickAddOffer}
                        onCreateInvoice={onCreateInvoice} onClickInvoice={onClickInvoice} offers={offers} total={total}
                        search={search} page={page} limit={limit} loading={loading}/>
            <RecipientSelectController ref={recipientRef} onSelect={onRecipientSelect}/>
        </>
    )
}