import {BaseFields, BaseModel, BaseProps, createFieldDef, FieldDefinition, ModelFieldType} from './baseModel';
import {ModelResponse, ResponseData} from "@dvrd/dvr-controls";
import {PaymentType} from "../util/interfaces";
import {activatePaymentMethod, deletePaymentMethod, getPaymentMethods} from "../util/requests/subscriptionRequests";

interface Props extends BaseProps {
    account_number?: string;
    issuer?: string;
    expires?: string;
    payment_type?: PaymentType;
    is_active?: boolean;
}

interface Fields extends BaseFields {
    account_number: string;
    issuer: string;
    expires: string;
    payment_type: PaymentType;
    is_active: boolean;
}

const fields: Fields = {
    id: -1n,
    created_at: '',
    updated_at: '',
    account_number: '',
    issuer: '',
    expires: '',
    payment_type: PaymentType.IDEAL,
    is_active: true,
};

const fieldsDefinition: { [key in keyof Fields]: FieldDefinition } = {
    id: createFieldDef(ModelFieldType.BIGINT),
    created_at: createFieldDef(ModelFieldType.STRING),
    updated_at: createFieldDef(ModelFieldType.STRING, undefined, true),
    account_number: createFieldDef(ModelFieldType.STRING),
    issuer: createFieldDef(ModelFieldType.STRING),
    expires: createFieldDef(ModelFieldType.STRING),
    is_active: createFieldDef(ModelFieldType.BOOLEAN),
    payment_type: createFieldDef(ModelFieldType.STRING, PaymentType.IDEAL),
};

const ignoreProps: (keyof Fields)[] = [];

export default class PaymentMethod extends BaseModel<Props, Fields> {
    static getAll = (limit: number, page: number, callback: ModelResponse<PaymentMethod[]>) => {
        getPaymentMethods({
            limit, page, callback: (data: ResponseData) => {
                const methods: Array<PaymentMethod> = data.success ? data.payment_methods.map(
                    (pm: any) => new PaymentMethod(pm)) : [];
                callback(methods, data.success, data);
            }
        });
    };

    constructor(props?: Props) {
        super(ignoreProps, fieldsDefinition, fields, props);
        this.build(props);
    }

    get accountNumber(): string {
        return this.fields.account_number;
    }

    set accountNumber(account_number: string) {
        this.fields.account_number = account_number;
    }

    get issuer(): string {
        return this.fields.issuer;
    }

    set issuer(issuer: string) {
        this.fields.issuer = issuer;
    }

    get expires(): string {
        return this.fields.expires;
    }

    set expires(expires: string) {
        this.fields.expires = expires;
    }

    get paymentType(): PaymentType {
        return this.fields.payment_type;
    }

    set paymentType(payment_type: PaymentType) {
        this.fields.payment_type = payment_type;
    }

    get isActive(): boolean {
        return this.fields.is_active;
    }

    set isActive(is_active: boolean) {
        this.fields.is_active = is_active;
    }

    activate = (callback: ModelResponse<PaymentMethod>) => {
        activatePaymentMethod({
            method_id: this.id, callback: (data: ResponseData) => {
                if (data.success) this.build(data.payment_method);
                callback(this, data.success, data);
            }
        });
    }

    delete = (callback: ModelResponse<PaymentMethod>) => {
        deletePaymentMethod({
            method_id: this.id, callback: (data: ResponseData) => {
                callback(this, data.success, data);
            }
        });
    }

    protected build = (props?: Props) => {
        this.constructFields(props);
        if (props) {
            // TODO
        }
    };
}