import {FetchMethod, sendFetch} from "@dvrd/dvr-controls";
import {InvoiceStatus} from "../interfaces";
import {WithCompanyId} from "./companyRequests";
import {Callback, Paginated, WithData} from "./index";
import {createParamUrl, URLS} from "../api";

interface CreateOfferItem {
    item_date: string | null;
    code: string | null;
    description: string;
    price: number;
    vat_percentage: number;
    quantity: number;
    incl_vat: boolean;
}

interface CreateOffer extends WithCompanyId {
    offer_date: string;
    status: InvoiceStatus;
    comment: string | null;
    offer_items: Array<CreateOfferItem>;
    relation_id: bigint;
}

export interface WithOfferID extends WithCompanyId {
    offer_id: bigint;
}

type GetOffers = WithCompanyId & Paginated & {
    search_value: string | null;
    relation_id: bigint | null;
}

type UpdateOffer = WithOfferID & CreateOffer;

interface SendOffer extends WithOfferID {
    recipients: Array<string> | null;
}

interface DownloadOffer extends WithOfferID {
    as_preview: boolean;
}

export function createOffer(params: WithData<CreateOffer> & Callback) {
    return sendFetch({
        url: URLS.POST.CREATE_OFFER,
        method: FetchMethod.POST,
        ...params,
    });
}

export function getOffers(params: WithData<GetOffers> & Callback) {
    return sendFetch({
        url: URLS.POST.GET_OFFERS,
        method: FetchMethod.POST,
        ...params,
    });
}

export function getOffer(params: WithOfferID & Callback) {
    return sendFetch({
        url: createParamUrl(URLS.GET.GET_OFFER, {
            company_id: params.company_id.toString(),
            offer_id: params.offer_id.toString()
        }),
        callback: params.callback,
    });
}

export function updateOffer(params: WithData<UpdateOffer> & Callback) {
    return sendFetch({
        url: URLS.PUT.UPDATE_OFFER,
        method: FetchMethod.PUT,
        ...params,
    });
}

export function sendOffer(params: WithData<SendOffer> & Callback) {
    return sendFetch({
        url: URLS.POST.SEND_OFFER,
        method: FetchMethod.POST,
        ...params,
    });
}

export function downloadOffer(params: WithData<DownloadOffer> & Callback) {
    return sendFetch({
        url: URLS.POST.DOWNLOAD_OFFER,
        method: FetchMethod.POST,
        ...params,
    })
}

export function deleteOffer(params: WithOfferID & Callback) {
    return sendFetch({
        url: createParamUrl(URLS.DELETE.DELETE_OFFER, {
            company_id: params.company_id.toString(),
            offer_id: params.offer_id.toString()
        }),
        method: FetchMethod.DELETE,
        callback: params.callback,
    });
}